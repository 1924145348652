import CodeExample from "components/docs/code-examples/CodeExample";
import HeaderBar from "components/docs/layout/HeaderBar";
import CrossGuideLinks from "components/docs/navigation/CrossGuideLinks";
import { MERGE_PRICING_PAGE } from "components/docs/navigation/links";
import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import { PricingInfoCard } from "components/docs/shared-components/NoticeCards";
import { StaticFancyPageContainerWithTOC } from "components/docs/shared-components/StaticFancyPageContainer";
import React from "react";
import { RouterLocation } from "types/types";
import { StaticImage } from "gatsby-plugin-image";
import { Alert } from "@merge-api/merge-javascript-shared";
import LINKS from "components/docs/navigation/links";
import NavigationScrollTrackerWithAnchor from "components/docs/navigation/NavigationScrollTrackerWithAnchor";
import SmallWidthSection from "components/docs/sections/SmallWidthSection";
import NavigationScrollTracker from "components/docs/navigation/NavigationScrollTracker";
import { Col } from "react-bootstrap";

interface Props {
  location: RouterLocation;
}

const SupplementalDataRemoteDataPage = ({ location }: Props) => {
  const title = "Remote Data";
  const description =
    "Access any data that you need, even if it's not part of Merge's unified Common Model";
  const tableOfContents = [
    {
      text: "Overview",
      anchorSlug: "overview",
    },
    {
      text: "When to use Remote Data",
      anchorSlug: "when-to-use-remote-data",
    },
    {
      text: "Enabling Remote Data",
      anchorSlug: "enabling-remote-data",
    },
    {
      text: "Accessing Remote Data",
      anchorSlug: "accessing-remote-data",
    },
    {
      text: "Other solutions",
      anchorSlug: "other-solutions",
    },
  ];

  return (
    <div className="pr-16">
      <StaticFancyPageContainerWithTOC
        headerOffset={-110}
        style={{ paddingBottom: 0 }}
        tableOfContents={tableOfContents}
      >
        <DocsHelmet title={title} description={description} />
        <Col lg={9} id="overview" key="overview" className="docs--tag-section">
          <NavigationScrollTracker>
            <HeaderBar title={title} subtitle={description}>
              <PricingInfoCard className="mb-9 mt-0">
                This feature is only available to customers on our <b>Professional</b> or{" "}
                <b>Enterprise</b> plans. View the{" "}
                <a href={MERGE_PRICING_PAGE} target="_blank" rel="noreferrer">
                  {" "}
                  Merge Plans
                </a>{" "}
                to learn more.
              </PricingInfoCard>
            </HeaderBar>
          </NavigationScrollTracker>
          <NavigationScrollTrackerWithAnchor
            headingLevel="h4"
            title="Overview"
            anchorSlug="overview"
            pageLoadOffsetInPx={-220}
            style={{ marginTop: 0, marginBottom: 12 }}
          >
            <>
              <p>
                Remote Data lets you access data from third-party integrations in its original
                format, even if it&apos;s not included in our Common Model. Merge does not normalize
                Remote Data, so the data structure may vary between integrations.
              </p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <StaticImage
                  src="../../assets/images/illustrations/remote-data.png"
                  alt="How Remote Data Works"
                  quality={100}
                  placeholder="none"
                  layout="constrained"
                  width={960}
                />
              </div>
            </>
            <Alert className="mb-6 mt-2" color="gray">
              <p className="mb-0">
                Remote Data will only be updated in a sync if there&apos;s a change to Common Model
                field. If you need updates to other non Common Model related fields found in Remote
                Data, you can create{" "}
                <a
                  href={LINKS.MERGE_FIELD_MAPPING_OVERVIEW.linkTo}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Field Mappings
                </a>{" "}
                for those fields as Field Mappings are considered Common Model fields.
              </p>
            </Alert>
          </NavigationScrollTrackerWithAnchor>

          <hr className="my-9" />
          <div className="flex flex-row">
            <div className="w-1/2">
              <NavigationScrollTrackerWithAnchor
                headingLevel="h4"
                title="When to use Remote Data"
                anchorSlug="when-to-use-remote-data"
                pageLoadOffsetInPx={220}
                style={{ marginTop: 0, marginBottom: 12 }}
              >
                <p>
                  {" "}
                  Remote Data should be used when our common data model does not offer specific
                  fields that you are interested in.
                </p>
                <p>
                  Let&apos;s say for example a company needs to get T-Shirt sizes for your
                  employees. Merge does not normalize this value but you will be able to access it
                  in Remote Data.
                </p>
              </NavigationScrollTrackerWithAnchor>
              <hr className="my-9" />
              <NavigationScrollTrackerWithAnchor
                headingLevel="h4"
                title="Enabling Remote Data"
                anchorSlug="enabling-remote-data"
                style={{ marginTop: 0, marginBottom: 12 }}
              >
                <p>
                  By default, Remote Data is disabled per Common Model. To enable it, go to the
                  Scopes tab in your Merge dashboard for the relevant Common Model(s) and toggle the
                  access to <b>Enabled</b>.
                </p>
                <div className="mb-6" style={{ display: "flex", justifyContent: "center" }}>
                  <StaticImage
                    src="../../assets/images/illustrations/remote-data-enable.png"
                    alt="Enable Remote Data"
                    quality={100}
                    placeholder="none"
                    layout="constrained"
                    width={960}
                  />
                </div>
                <p>
                  Then set Merge&apos;s <code>include_remote_data</code> query parameter to{" "}
                  <code>true</code> to see Remote Data in your API responses.
                </p>
              </NavigationScrollTrackerWithAnchor>
              <hr className="my-9" />
              <NavigationScrollTrackerWithAnchor
                headingLevel="h4"
                title="Accessing Remote Data"
                anchorSlug="accessing-remote-data"
              >
                <p>
                  Remote Data is an array of response objects per API path and ordering can change
                  over time.
                </p>
                <p>
                  While accessing Remote Data, we highly recommend asserting on the specific API
                  path variable instead of accessing <code>remote_data[index]</code>.
                  <code>remote_data[index].path</code>is a variable that represents the API path and
                  will differ for each common model and integration.
                </p>
              </NavigationScrollTrackerWithAnchor>
              <hr className="my-9" />
              <NavigationScrollTrackerWithAnchor
                headingLevel="h4"
                title="What if Remote Data doesn't have what you are looking for?"
                anchorSlug="other-solutions"
              >
                <p>
                  If you&apos;re looking for data from an endpoint that we do not include in our
                  Common Models, you may need to create an{" "}
                  <a
                    href={LINKS.PASSTHROUGH_REQUEST_OVERVIEW.linkTo}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Authenticated Passthrough Request
                  </a>{" "}
                  instead.
                </p>
              </NavigationScrollTrackerWithAnchor>
            </div>
            <div className="flex pl-12 w-1/2">
              <CodeExample
                folder="supplemental-data/remote-data/remote-data-payload"
                codeBlockName="Example response"
                fileBaseName="example"
                colorScheme="light"
                hasLineNumbers={false}
                isVerticallyPadded={false}
                className="pt-0 w-full"
              />
            </div>
          </div>

          <CrossGuideLinks location={location} style={{ marginTop: "36px" }} />
        </Col>
      </StaticFancyPageContainerWithTOC>
    </div>
  );
};

export default SupplementalDataRemoteDataPage;
